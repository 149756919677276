import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import OrderStatus from '@/types/OrderStatus';
import VerificationDocument from './VerificationDocument';
import VerificationDocumentInfo from './VerificationDocumentInfo';
import Address from '../common/Address';
import OrderCustomerField from './OrderCustomerField';
import Gender from '@/types/Gender';
import OrderHistoryEntry from '@/domains/order/OrderHistoryEntry';
import InternationalBankAccount from '@/types/InternationalBankAccount';

const notWhitespacesOnlyRegExp = /^(?!\s*$).+/;

interface Props {
  id: string;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  invoiceAddress: Address;
  deliveryAddress: Address | null;
  coPaymentGrossC2B: Money;
  status: OrderStatus;
  parkedInternalNote: string;
  parkedExternalNote: string;
  verificationDocumentsInfo: VerificationDocumentInfo[];
  fileUploadHint: string;
  customerFields: OrderCustomerField[];
  codeToken: string;
  parkedUntilDate: Date;
  updatedAt: Date;
  editor: string;
  history: OrderHistoryEntry[];
  internationalBankAccount?: InternationalBankAccount;
  erpOrderId: string;
  campaignId: string;
  campaignType: string;
  verificationInstruction?: string;
  verificationDocument?: VerificationDocument;
}

class Order extends Domain implements Props {
  public static createAll(orders: any[]): Order[] {
    return orders.map(Order.create);
  }

  public static create(data: any): Order {
    const gender: Gender =
      data.invoiceAddress && typeof data.invoiceAddress.gender === 'string'
        ? Gender.ALL.filter((g) => g.id === data.invoiceAddress.gender)[0]
        : data.gender;
    const deliveryAddress =
      data.deliveryAddress?.street &&
      Address.create({
        street: data.deliveryAddress.street,
        zipCode: data.deliveryAddress.zipCode,
        city: data.deliveryAddress.city,
        additionalAddress: data.deliveryAddress.additionalAddress,
      });
    return new Order(
      data.id,
      gender,
      data.invoiceAddress && data.invoiceAddress.firstName,
      data.invoiceAddress && data.invoiceAddress.lastName,
      data.email,
      data.phone,
      Address.create({
        street: data.invoiceAddress && data.invoiceAddress.street,
        zipCode: data.invoiceAddress && data.invoiceAddress.zipCode,
        city: data.invoiceAddress && data.invoiceAddress.city,
        additionalAddress:
          data.invoiceAddress && data.invoiceAddress.additionalAddress,
      }),
      deliveryAddress,

      data.coPaymentGrossC2B || {
        amount: 0,
        currency: 'EUR',
      },
      OrderStatus.ALL.filter((status) => status.id === data.status)[0],
      data.parkedInternalNote,
      data.parkedExternalNote,
      data.verificationDocumentsInfo
        ? data.verificationDocumentsInfo.map(VerificationDocumentInfo.create)
        : [],
      data.fileUploadHint,
      data.customerFields || [],
      data.codeToken,
      data.parkedUntilDate && new Date(data.parkedUntilDate),
      data.updatedAt && new Date(data.updatedAt),
      data.editor,
      OrderHistoryEntry.createAll(data.history || []),
      data.internationalBankAccount !== null
        ? data.internationalBankAccount
        : undefined,
      data.erpOrderId,
      data.campaignId,
      data.campaignType,
      data.verificationInstruction,
      //data.verificationDocument ? data.verificationDocuments.map(VerificationDocument.create) : [],
      data.verificationDocument && VerificationDocument.create(data.verificationDocument)
    );
  }

  private constructor(
    public id: string,
    public gender: Gender,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public invoiceAddress: Address,
    public deliveryAddress: Address,
    public coPaymentGrossC2B: Money,
    public status: OrderStatus,
    public parkedInternalNote: string,
    public parkedExternalNote: string,
    public verificationDocumentsInfo: VerificationDocumentInfo[],
    public fileUploadHint: string,
    public customerFields: OrderCustomerField[],
    public codeToken: string,
    public parkedUntilDate: Date,
    public updatedAt: Date,
    public editor: string,
    public history: OrderHistoryEntry[],
    public internationalBankAccount: InternationalBankAccount,
    public erpOrderId: string,
    public campaignId: string,
    public campaignType: string,
    public verificationInstruction: string,
    public verificationDocument: VerificationDocument,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      id: {
        presence: true,
        format:
          /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      },
      gender: {
        presence: true,
      },
      firstName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      lastName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      email: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      invoiceAddress: {
        presence: true,
      },
      deliveryAddress: {
        presence: false,
      },
      coPaymentGrossC2B: {
        presence: true,
      },
      status: {
        presence: true,
      },
      codeToken: {
        presence: true,
      },
      parkedInternalNote: {
        presence: false,
      },
      parkedExternalNote: {
        presence: false,
      },
      parkedUntilDate: {
        presence: false,
      },
      updatedAt: {
        presence: false,
      },
      lastUpdatedUser: {
        presence: false,
      },
    };
  }

  public clone(): Order {
    return Order.create(this);
  }
}

export default Order;
